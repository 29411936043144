/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Col, Container, Row, Card, CardBody } from "reactstrap";
import { useHistory } from "react-router-dom";
import { saveAs } from "file-saver";
import empty from "is-empty";
import axios, { get } from "axios";
import { toast, ToastContainer } from "react-toastify";
import config from "../../../../config";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import Breadcrumbs from "../../../Helper/Breadcrumbs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { TextField, Popover, Typography, Divider } from "@mui/material";
// import {DatePicker} from 'react-datepicker';
import Modal from "react-bootstrap/Modal";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import { FaChevronDown } from "react-icons/fa";
import { Box, Slider } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import MyVerticallyCenteredModal from "../Report/MandapdetailsModal";
import { MdDelete } from "react-icons/md";
import moment from "moment";
import _ from "lodash";
import CryptoJS from "crypto-js";
import Cookies from "js-cookie";
import { alpha, styled } from "@mui/material/styles";
import { pink } from "@mui/material/colors";
import Switch from "@mui/material/Switch";

const Report = (props) => {
  let history = useHistory();
  let tokenKey = {
    headers: { Authorization: "Token " + sessionStorage.getItem("tokenKey") },
  };

  let category = null;
  let breadcrumbData = [{ title: "Mandap Report", href: "#" }];
  let toastAttr = {
    theme: "colored",
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  const [mobileNumber, setMobileNumber] = useState("");
  const [customerName, setcustomerName] = useState("");
  const [eventType, seteventType] = useState("");
  const [eventDate, seteventDate] = useState("");
  const [telecallerName, settelecallerName] = useState("");
  const [showSuggestions, setShowSuggestions] = useState(false);
  // <<<<<====================CRM ACCESSS=================>>>>>>

  useEffect(() => {
    const queryParams = new URLSearchParams(window?.location?.search);
    const encryptedToken = queryParams?.get("z");
    const crmleadId = queryParams?.get("lead");
    const role = queryParams?.get("role");
    const leadphoneno = queryParams?.get("phonenumber");
    const leadname = queryParams?.get("leadname");
    const eventtype = queryParams?.get("eventtype");
    const eventdate = queryParams?.get("eventdate");

    if (encryptedToken) {
      const secretKey = "adminKey";
      const bytes = CryptoJS.AES.decrypt(
        decodeURIComponent(encryptedToken),
        secretKey
      );
      const token = bytes.toString(CryptoJS.enc.Utf8);
      if (token) {
        Cookies.set("tokenKey", token, { expires: 1 });
        localStorage.removeItem("crm_lead_id");
        localStorage.setItem("crm_lead_id", crmleadId);
        localStorage.removeItem("role");
        localStorage.setItem("role", role);
        localStorage.removeItem("leadphoneno");
        localStorage.setItem("leadphoneno", leadphoneno);
        if (leadphoneno !== "" && leadphoneno !== null) {
          let cleanedPhoneNumber = leadphoneno
            .replace(/\D/g, "")
            .replace(/^91/, "");
          setMobileNumber(cleanedPhoneNumber);
        }
        localStorage.removeItem("leadname");
        localStorage.setItem("leadname", leadname);
        if (leadname !== "" && leadname !== null) {
          setcustomerName(leadname);
        }
        localStorage.removeItem("eventtype");
        localStorage.setItem("eventtype", eventtype);

        localStorage.removeItem("eventdate");
        localStorage.setItem("eventdate", eventdate);
      } else {
        console.log("error");
      }
    }
  }, []);

  useEffect(() => {
    axios.get(config.service + "/masters/city/").then((res) => {
      let city = [{ label: "Select City", value: "" }];
      for (let i in res.data)
        city.push({ label: res.data[i].name, value: res.data[i].name });

      setCityList(city);
      // setsavedCity(city[0].value);

      // sessionStorage.setItem('city',city[0].value)
    });
    axios
      .get(config.service + "/masters/mandap_classification/", tokenKey)
      .then((res) => {
        let type = [];
        res.data.map((obj) => {
          type.push({ value: obj.id, label: obj.classification_name });
        });
        setCategoryList(type);
      });
    axios.get(config.service + "/accounts/base/agent", tokenKey).then((res) => {
      let type = [];
      res.data.data.map((obj) => {
        type.push({
          value: obj?.user_id?.id,
          label: `${obj?.user_id?.first_name}-${obj?.mobile}`,
        });
      });
      setAgentList(type);
    });
  }, []);

  const [date1, setdate] = useState("");
  const [cityList, setCityList] = useState([]);
  const [selLocality, setselLocality] = useState([]);
  const [localityList, setlocalityList] = useState([]);
  const [tableData, settableData] = useState([]);
  const [query, setQuery] = useState("");
  const [mandapName, setmandapname] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [error, setError] = useState({});
  const [venueList, setvenueList] = useState([]);
  const [selVenue, setselVenue] = useState([]);
  const [isPageLoading, setpageLoading] = useState(false);
  const [isexportingClicked, setexportingClicked] = useState(false);
  const [modalShow, setModalShow] = React.useState(false);
  const [mandapDetailmodalData, setmandapDetailmodalData] = useState([]);
  const [kms, setkms] = useState("");

  const onChangeVenue = (value, e) => {
    if (kms !== "" && kms !== "0") {
      // If kms is not empty, store only the last clicked element
      if (e.action === "select-option") {
        setselVenue([e.option]); // Store only the last clicked element
      } else if (e.action === "deselect-option") {
        setselVenue([]); // Clear if an option is deselected
      }
      return;
    }

    if (e.action === "deselect-option")
      setselVenue(value.filter((o) => o.value !== "*"));
    else if (value.length === venueList.length - 1) setselVenue(venueList);
    else setselVenue(value);
  };

  const [budgetChanged, setBudgetChanged] = useState(0);
  const [budget, setbudget] = useState([5000, 500000]);
  const [count, setcount] = useState(0);
  const [anchor, setAnchor] = useState(null);
  const [budgetAnchor, setbudgetAnchor] = useState(null);
  const [budgetLabel, setbudgetLabel] = useState("Select Budget");
  const handleRangeClose = (keyName) => {
    if (keyName === "capacity") {
      setAnchor(null);
      setcapacityLabel(capacity[0] + " to " + capacity[1]);
      setcount(count + 1);
    } else if (keyName === "budget") {
      setbudgetAnchor(null);
      setbudgetLabel(budget[0] + " to " + budget[1]);
      setBudgetChanged(budgetChanged + 1);
    }
  };
  const openBudgetPopover = (event) => {
    setbudgetAnchor(event.currentTarget);
  };
  const [capacity, setcapacity] = useState([0, 500]);
  const [iscapacitySelected, setcapacityselected] = useState(false);
  const [isbudget, setbudgetselected] = useState(false);
  const [capacityLabel, setcapacityLabel] = useState("Select Capacity");
  const handleRangeChange = (event, newValue, setFn) => {
    setFn(newValue);
  };

  const openCapacityPopover = (event) => {
    setAnchor(event.currentTarget);
  };

  // const onChangeLocality = (value, e) => {
  //   if(kms !== ''){
  //   }
  //   if (e.action === "select-option" && e.option.value === "*")
  //     setselLocality(localityList);
  //   else if (e.action === "deselect-option" && e.option.value === "*")
  //     setselLocality([]);
  //   else if (e.action === "deselect-option")
  //     setselLocality(value.filter((o) => o.value !== "*"));
  //   else if (value.length === localityList.length - 1)
  //     setselLocality(localityList);
  //   else setselLocality(value);
  // };

  const onChangeLocality = (value, e) => {
    if (kms !== "" && kms !== "0") {
      // If kms is not empty, store only the last clicked element
      if (e.action === "select-option") {
        setselLocality([e.option]); // Store only the last clicked element
      } else if (e.action === "deselect-option") {
        setselLocality([]); // Clear if an option is deselected
      }
      return;
    }

    if (e.action === "select-option" && e.option.value === "*") {
      setselLocality(localityList);
    } else if (e.action === "deselect-option" && e.option.value === "*") {
      setselLocality([]);
    } else if (e.action === "deselect-option") {
      setselLocality(value.filter((o) => o.value !== "*"));
    } else if (value.length === localityList.length - 1) {
      setselLocality(localityList);
    } else {
      setselLocality(value);
    }
  };

  function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== "") {
      const cookies = document.cookie.split(";");
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.startsWith(name + "=")) {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
          break;
        }
      }
    }
    return cookieValue;
  }
  function handleKeyDown(event) {
    if (event.keyCode === 38) {
      // Up arrow key
      event.preventDefault();
      if (selectedIndex === 0) {
        setSelectedIndex(suggestions.length - 1);
      } else {
        setSelectedIndex(selectedIndex - 1);
      }
    } else if (event.keyCode === 40) {
      // Down arrow key
      event.preventDefault();
      if (selectedIndex === suggestions.length - 1) {
        setSelectedIndex(0);
      } else {
        setSelectedIndex(selectedIndex + 1);
      }
    } else if (event.keyCode === 13) {
      // Enter key
      event.preventDefault();
      if (selectedIndex !== null) {
        handleSelectSuggestion(suggestions[selectedIndex]);
        setSelectedIndex(null);
      }
    }
  }

  function handleMouseOver(index) {
    setSelectedIndex(index);
  }

  function handleMouseOut() {
    setSelectedIndex(null);
  }

  function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      // Cleanup timeout if value or delay changes
      return () => {
        clearTimeout(handler);
      };
    }, [value, delay]);

    return debouncedValue;
  }
  const debouncedSearchTerm = useDebounce(mandapName, 450);

  useEffect(() => {
    if (debouncedSearchTerm) {
      // Perform search or API call here

      console.log("Searching for:", debouncedSearchTerm);
      if (mandapName?.length >= 3) {
        fetch(config.service + "/masters/admin/report/suggest_names/", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": getCookie("csrftoken"),
          },
          body: JSON.stringify({ query: mandapName }),
        })
          .then((response) => response.json())
          .then((data) => {
            setSuggestions(data);
          });
      } else {
        setSuggestions([]);
      }
    }
  }, [debouncedSearchTerm]);

  function handleChange(event) {
    const value = event.target.value;
    // setQuery(value);
    setmandapname(value);
    setShowSuggestions(value.length > 3);
  }

  const handleDateChange = (date) => {
    // setSelectedDate(date);
    setdate(date);
  };

  const handleCity = (e) => {
    e.preventDefault();
    sessionStorage.setItem("city", e.target.value);
    setsavedCity(e.target.value);
    setlocalityList([]);
    setselLocality([]);

    get(config.service + "/masters/guest/area/list/?&search=" + e.target.value)
      .then((res) => {
        let area = [];
        for (let i in res.data)
          area.push({ label: res.data[i].name, value: res.data[i].id });
        setlocalityList(area);
        get(config.service + "/masters/mandap_type_count/").then((res) => {
          let type = [];
          for (let i in res.data) {
            let typeLabel = res.data[i].mandap_type;
            type.push({ label: typeLabel, value: res.data[i].id });
          }
          setvenueList(type);
        });
      })
      .catch((err) => {});
  };
  const [savedCity, setsavedCity] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [publishChoice, setPublishChoice] = useState("");
  const [categoryChoice, setCategoryChoice] = useState("");
  const [categoryList, setCategoryList] = useState([]);
  const [agentList, setAgentList] = useState([]);
  const [agentSelected, setAgentSelected] = useState("");
  const [searchedKM, setSearchedKM] = useState({
    kms: "",
    locality: "",
  });

  const fetchModalData = (name) => {
    fetch(config.service + `/masters/mandap_report_date/?name=${name}`)
      .then((response) => response.json())
      .then((data) => {
        console.log(data, "data");
        setModalData(data);
      })
      .catch((error) => setError(error));
  };

  const exportdata = (event) => {
    // event.preventDefault();
    setexportingClicked(true);
    var city_name = savedCity;
    // var areaString
    if (!empty(selLocality)) {
      var areaString = "";
      selLocality.map((loc, index) => {
        areaString += loc.value + ",";
      });
      areaString = areaString.replace(/,\s*$/, "");
    }
    if (!empty(selVenue)) {
      var venue = "";
      selVenue.map((loc, index) => {
        venue += loc.value + ",";
      });
      venue = venue.replace(/,\s*$/, "");
    }
    if (!empty(date1)) {
      var date2;
      date2 = moment(date1).format("MM/DD/YYYY");
    }
    if (count > 0) var seating_capacity = capacity[0] + "," + capacity[1];
    if (budgetChanged > 0) var getbudget = budget[0] + "," + budget[1];

    if (
      (!_.isEmpty(agentSelected) || !_.isEmpty(date2)) &&
      query === "" &&
      _.isEmpty(city_name) &&
      _.isEmpty(areaString) &&
      _.isEmpty(seating_capacity) &&
      _.isEmpty(getbudget) &&
      _.isEmpty(venue) &&
      _.isEmpty(publishChoice) &&
      _.isEmpty(categoryChoice)
    ) {
      fetch(config.service + "/masters/mandap_report_generate_agent/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // 'X-CSRFToken': getCookie('csrftoken')
        },
        body: JSON.stringify({
          date: date2,
          agent_id: agentSelected,
        }),
      })
        .then((response) => response.blob())
        .then((blob) => saveAs(blob, "example.xlsx"))
        .catch((error) => setError(error))
        .finally(() => {
          setexportingClicked(false);
        });
    } else {
      fetch(config.service + "/masters/mandap_report_generate/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // 'X-CSRFToken': getCookie('csrftoken')
        },
        body: JSON.stringify({
          id: query,
          query: "",
          city: city_name,
          area: areaString,
          venue: venue,
          date: date2,
          seating_capacity: seating_capacity,
          budget: getbudget,
          publishChoice: publishChoice,
          categoryChoice: categoryChoice,
          agent_id: agentSelected,
        }),
      })
        .then((response) => response.blob())
        .then((blob) => saveAs(blob, "example.xlsx"))
        .catch((error) => setError(error))
        .finally(() => {
          setexportingClicked(false);
        });
    }
  };

  function removeDuplicates(array) {
    let uniqueIds = new Set();
    return array.filter((obj) => {
      if (!uniqueIds.has(obj.id)) {
        uniqueIds.add(obj.id);
        return true;
      }
      return false;
    });
  }

  // const handleresetfilter = () => {
  //   setselLocality([]);
  //   setselVenue([]);
  //   setbudget([5000, 500000]);
  //   setbudgetselected(false);
  //   setBudgetChanged(0);
  //   setcapacity([0, 500]);
  //   setbudgetLabel("Select Budget");
  //   setcapacityLabel("Select Capacity");
  //   setcapacityselected(false);
  // };

  const handleSubmit = async (event) => {
    setSearchedKM({
      kms: kms,
      locality:
        selLocality && selLocality[0] && selLocality[0].label
          ? selLocality[0].label
          : "",
    });
    // event.preventDefault();
    if (query !== "" && savedCity !== null && kms > 0) {
      toast.warning(
        "Either Select Mandap Name or City for proximity radius filteration",
        toastAttr
      );
    }
    let nearbyData = [];
    settableData([]);
    var city_name = savedCity;
    // var areaString
    setpageLoading(true);
    if (!empty(selLocality)) {
      var areaString = "";
      selLocality.map((loc, index) => {
        areaString += loc.value + ",";
      });
      areaString = areaString.replace(/,\s*$/, "");
    }
    if (!empty(selVenue)) {
      var venue = "";
      selVenue.map((loc, index) => {
        venue += loc.value + ",";
      });
      venue = venue.replace(/,\s*$/, "");
    }
    if (!empty(date1)) {
      var date2;
      date2 = moment(date1).format("MM/DD/YYYY");
    }
    if (count > 0) var seating_capacity = capacity[0] + "," + capacity[1];
    if (budgetChanged > 0) var getbudget = budget[0] + "," + budget[1];
    if (
      (savedCity !== null && selLocality?.length > 0 && kms > 0) ||
      (query !== "" && kms > 0)
    ) {
      await axios
        .post(`https://api.bookwedgo.com/map/nearByMandapam`, {
          id: query,
          km: kms,
          area: selLocality?.[0]?.value,
          mandap_type_id_id: categoryChoice,
          mandapStatus: publishChoice,
          seating_capacity: iscapacitySelected
            ? capacity[0] + "," + capacity[1]
            : "",
          mandap_classification_id_id: selVenue?.[0]?.value,
          budget: isbudget ? budget[0] + "," + budget[1] : "",
          date: date2,
        })
        .then((res) => {
          nearbyData = [...nearbyData, ...res?.data?.data];
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }

    fetch(config.service + "/masters/mandap_report/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: query,
        city: city_name,
        area: areaString,
        venue: venue,
        date: date2,
        seating_capacity: seating_capacity,
        budget: getbudget,
        publishChoice: publishChoice,
        categoryChoice: categoryChoice,
      }),
    })
      .then((response) => response?.json())
      .then((data) => {
        setpageLoading(false);
        let appendData = [...nearbyData, ...data];

        let uniqueObjects = removeDuplicates(appendData);

        settableData(uniqueObjects);
      })
      .catch((error) => {
        setpageLoading(false);
        setError(error);
        setpageLoading(true);
      });
  };
  function handleSelectSuggestion(suggestion) {
    setQuery(suggestion);
    setSuggestions([]);
    setShowSuggestions(false);
  }

  const [selectedIndex, setSelectedIndex] = useState(null);

  const formatDate = (inputDate) => {
    let formatDate = inputDate?.split(" ");
    let actualDate = formatDate?.[0];
    return actualDate;
  };

  const cols = [
    {
      dataField: "name",
      text: "Mandap Name",
      headerStyle: () => {
        return { width: "300px" };
      },
      formatter: (cell, row) => (
        <div style={{ width: "300px", fontSize: "14px", lineHeight: "normal" }}>
          {cell}
        </div> // set the width and font size of the ID cell to 50px and 14px respectively
      ),
    },
    {
      dataField: "areaname",
      text: "Area",
      headerStyle: () => {
        return { width: "250px" };
      },
      formatter: (cell) => {
        return <p style={{ fontSize: "14px", lineHeight: "normal" }}>{cell}</p>;
      },
    },
    {
      dataField: "address",
      text: "Address",
      headerStyle: () => {
        return { width: "1000px" };
      },
      formatter: (cell, row) => {
        return (
          <div
            style={{ width: "300px", fontSize: "14px", lineHeight: "normal" }}
          >
            {row?.address}
          </div>
        );
      },
    },
    {
      dataField: "live_status",
      text: "Publish Status",
      headerStyle: () => {
        return { width: "1000px" };
      },
    },
    {
      dataField: "distanceInMeters",
      text: "Distance in KMs",
      headerStyle: () => {
        return { width: "1000px" };
      },
      formatter: (cell, row) => {
        return (
          <div>
            {row?.distanceInMeters
              ? (row?.distanceInMeters / 1000).toFixed(2) + "KM"
              : "-"}
          </div>
        );
      },
    },
    {
      dataField: "booked_slot",
      text: "Booked Slot",
      headerStyle: () => {
        return { width: "250px" };
      },
      formatter: (cell, row) => {
        if (cell?.length > 1) {
          return cell?.map((i) => (
            <ul style={{ listStyle: "none" }}>
              <li style={{ padding: "6px 0px" }}> {i}</li>{" "}
            </ul>
          ));
        } else if (cell?.length === 1) {
          return <p>{cell}</p>;
        } else {
          return <p>-</p>;
        }
      },
    },

    {
      dataField: "status",
      text: "Available Status",
      headerStyle: () => {
        return { width: "250px" };
      },
      formatter: (cell) => {
        return <p style={{ fontSize: "14px", lineHeight: "normal" }}>{cell}</p>;
      },
    },
    // {
    //   dataField : 'address',
    //   text:'Address',
    //   headerStyle : () => { return{width : '800px'}}
    // },
    {
      dataField: "capcity",
      text: "Hall Capacity",
      headerStyle: () => {
        return { width: "250px" };
      },
    },
    {
      dataField: "dining_capacity",
      text: "Dining Capacity",
      headerStyle: () => {
        return { width: "250px" };
      },
    },
    {
      dataField: "ac",
      text: "Air Conditioning",
      headerStyle: () => {
        return { width: "250px" };
      },
    },
    {
      dataField: "carparking",
      text: "Car Parking",
      headerStyle: () => {
        return { width: "250px" };
      },
    },

    {
      dataField: "rooms",
      text: "Rooms",
      headerStyle: () => {
        return { width: "250px" };
      },
    },
    {
      dataField: "foodtype",
      text: "Food Type",
      headerStyle: () => {
        return { width: "250px" };
      },
    },
    {
      dataField: "hf_am_price",
      text: "Half AM Price",
      headerStyle: () => {
        return { width: "250px" };
      },
    },
    {
      dataField: "hf_pm_price",
      text: "Half PM Price",
      headerStyle: () => {
        return { width: "1000px" };
      },
      // formatter: (cell, row) => {
      //   if (cell?.length > 1) {
      //     return cell?.map((i) => (
      //       <ul style={{ listStyle: "none" }}>
      //         <li style={{ padding: "6px 0px" }}> {i}</li>{" "}
      //       </ul>
      //     ));
      //   } else if (cell?.length === 1) {
      //     return <p>{cell}</p>;
      //   } else {
      //     return <p>-</p>;
      //   }
      // },
    },
    {
      dataField: "fd_price",
      text: "Full Day Price",
      headerStyle: () => {
        return { width: "1000px" };
      },
    },
    {
      dataField: "reservation_price",
      text: "Reservation Price",
      headerStyle: () => {
        return { width: "1000px" };
      },
    },
    {
      dataField: "sales_data.type",
      text: "Type",
      headerStyle: () => {
        return { width: "1000px" };
      },
      formatter: (cell) => {
        return (
          <p
            style={{
              fontSize: "14px",
              lineHeight: "normal",
              whiteSpace: "nowrap",
            }}
          >
            {cell}
          </p>
        );
      },
    },
    {
      dataField: "last_updated_date_by_sales",
      text: "Visited Date/Booked Date",
      headerStyle: () => {
        return { width: "1000px" };
      },
      formatter: (cell) => {
        return (
          <p style={{ fontSize: "14px", lineHeight: "normal" }}>
            {formatDate(cell)}
          </p>
        );
      },
    },

    {
      dataField: "sales_data.created_by",
      text: "Created By Agent",
      headerStyle: () => {
        return { width: "1000px" };
      },
    },

    {
      dataField: "sales_data.date",
      text: "Date Updated By Sales Team",
      headerStyle: () => {
        return { width: "1000px" };
      },
      formatter: (cell) => {
        return (
          <p style={{ fontSize: "14px", lineHeight: "normal" }}>
            {formatDate(cell)}
          </p>
        );
      },
    },
    {
      dataField: "kae_name",
      text: "Kae name",
      headerStyle: () => {
        return { width: "1000px" };
      },
    },
    {
      dataField: "kae_contact_number",
      text: "Kae contact",
      headerStyle: () => {
        return { width: "1000px" };
      },
    },
    {
      text: "Details",

      formatter: (cell, row) => (
        <button
          className="btn btn-info"
          onClick={() => {
            setSelectedRow(row);
            setShowModal(true);
            fetchModalData(row.id);
          }}
        >
          View Details
        </button>
      ),
    },
    {
      text: "View Mandap Details",

      formatter: (cell, row) => (
        <button
          className="btn btn-info"
          onClick={() => {
            setmandapDetailmodalData([]);
            setModalShow(true);
            let url = `${config.service}/masters/mandap_full_details/?&mandap_id=${row.id}`;
            axios
              .get(url, tokenKey)
              .then((response) => {
                // Handle the response
                setmandapDetailmodalData(response?.data);
              })
              .catch((error) => {
                // Handle errors
                console.error("Error making GET request:", error);
              });
          }}
        >
          View Mandap Details
        </button>
      ),
    },
  ];

  const modalColumns = [
    {
      dataField: "month",
      text: "Month",
      formatter: (cell) => (
        <div style={{ fontSize: "14px", width: "50px", whiteSpace: "nowrap" }}>
          {cell}
        </div>
      ),
    },
    {
      dataField: "dates",
      text: "Blocked Dates",
      formatter: (cell) => (
        <div style={{ fontSize: "14px", width: "200px", whiteSpace: "nowrap" }}>
          {cell.map((dateInfo, index) => (
            <div key={index}>
              <p>Date: {dateInfo.date}</p>
              <p>Slot: {dateInfo.slot}</p>
              <p>Start Time: {dateInfo.start_time}</p>
              <p>End Time: {dateInfo.end_time}</p>
            </div>
          ))}
        </div>
      ),
    },
  ];

  const renderForm = (row) => {
    if (row)
      history.push({
        pathname: "/masters/cityForm",
        state: {
          id: row.id,
          // vendor_id : propsState.id,
          editMode: true,
        },
      });
    else
      history.push({
        pathname: "/masters/cityForm",
        state: {
          // vendor_id : propsState.id,
        },
      });
  };

  const [searchTerm, setSearchTerm] = useState("");
  const [seletedMadapModal, setSelectedMadapModal] = useState(false);
  const [selected, setSelected] = useState([]);
  const [selectedMandap, setSelectedMandap] = useState([]);

  const [customerchecked, setcustomerChecked] = React.useState(false);

  const handlecustomertoggle = (event) => {
    setcustomerChecked(event.target.checked);
  };

  const eventtype = localStorage.getItem("event_type");
  const eventdate = localStorage.getItem("event_date");

  const PinkSwitch = styled(Switch)(({ theme }) => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: pink[600],
      "&:hover": {
        backgroundColor: alpha(pink[600], theme.palette.action.hoverOpacity),
      },
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: pink[600],
    },
  }));

  // Function to handle input change and filter data
  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleMobileNumberChange = (e) => {
    setMobileNumber(e.target.value);
  };

  const handlecustomerName = (e) => {
    setcustomerName(e.target.value);
  };
  const handleEventType = (e) => {
    seteventType(e.target.value);
  };
  const handleChangeEventDate = (e) => {
    seteventDate(e.target.value);
  };

  const handletelecallername = (e) => {
    settelecallerName(e.target.value);
  };

  // Function to filter table data based on search term
  const filteredData = tableData?.filter((item) =>
    item.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleRemoveSelectedMandap = (index) => {
    if (index !== -1) {
      const newSelected = [...selected];
      newSelected.splice(index, 1);
      setSelected(newSelected);
      setSelectedMandap((prevSelected) =>
        prevSelected.filter((selectedRow, i) => i !== index)
      );
    }
  };

  let options = paginationFactory({
    sizePerPage: 20,
    paginationSize: 3,
    hideSizePerPage: true,
    hidePageListOnlyOnePage: true,
    totalSize: filteredData.length,
  });

  const selectRow = {
    mode: "checkbox",
    style: { backgroundColor: "#e8e8e8" },
    selected,
    hideSelectAll: true,
    onSelect: (row, isSelect, rowIndex, e) => {
      if (isSelect && selected.length >= 5) {
        toast.warning("Only 5 Mandap are allowed to share", toastAttr);
        return false;
      }

      const newSelected = [...selected];
      if (isSelect) {
        newSelected.push(row.id);
        setSelected(newSelected);
        setSelectedMandap((prevSelected) => [...prevSelected, row]);
      } else {
        const index = newSelected.indexOf(row.id);
        if (index !== -1) {
          newSelected.splice(index, 1);
          setSelected(newSelected);
        }
        setSelectedMandap((prevSelected) =>
          prevSelected.filter((selectedRow, i) => i !== index)
        );
      }
    },
  };
  class WatiMsg {
    template_name = "bwg_sharing_template";
    broadcast_name = "string";
    parameters = [
      {
        name: "name",
        value: "",
      },
      {
        name: "customer_address",
        value: "coimbatore",
      },
      {
        name: "1",
        value: "wedding/Coimbatore/kalyana-mandapam",
      },
    ];

    constructor(data) {
      if (data) {
        if (data?.name) {
          this.parameters[0].value = data?.name;
        }
        if (data?.city) {
          this.parameters[1].value = data?.city;
        }
        if (data?.name && data?.city && data?.mandap_type && data?.id) {
          this.parameters[2].value = this.convertUrl(data);
        }
      }
    }

    generateUrl(data) {
      let url = "wedding/";
      url += data?.city;
      url += "/";
      url += data?.mandap_type.toLowerCase().replace(/[^a-zA-Z0-9]+/g, "-");
      url += "/";
      url += data?.name.toLowerCase().replace(/[^a-zA-Z0-9]+/g, "-");
      url += "-in-";
      url += data?.areaname.toLowerCase().replace(/[^a-zA-Z0-9]+/g, "-");
      url += "/" + data?.id;

      return url;
    }

    convertUrl(eachItem) {
      return `wedding/${eachItem.city}/${eachItem.mandap_type
        .replace(/[!@#$%^\\/&*]/g, "")
        .replace(/\//g, "")}/${eachItem.name
        .replace(/[!@#$%^\\/&*]/g, "")
        .replaceAll(/\//g, "")}/${eachItem.id}`
        .toLowerCase()
        .replace(/\s+/g, "-");

      // -in-${eachItem.areaname
      // .replace(/[!@#$%^\\/&*]/g, '')
      // .replaceAll(/\//g, '')}
    }
  }

  let success = true;

  const sendmessagestovendor = async (vendordata, vendornumber) => {
    const apiKey =
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI3MGIwNzcyMS1lNmFiLTQ5MDQtOWMxMi1lZWQ0ZWUwMzdjMjkiLCJ1bmlxdWVfbmFtZSI6ImluZm9AYm9va3dlZGdvLmNvbSIsIm5hbWVpZCI6ImluZm9AYm9va3dlZGdvLmNvbSIsImVtYWlsIjoiaW5mb0Bib29rd2VkZ28uY29tIiwiYXV0aF90aW1lIjoiMDIvMjkvMjAyNCAwNjozNTowMCIsImRiX25hbWUiOiJtdC1wcm9kLVRlbmFudHMiLCJ0ZW5hbnRfaWQiOiIzMDU0NzYiLCJodHRwOi8vc2NoZW1hcy5taWNyb3NvZnQuY29tL3dzLzIwMDgvMDYvaWRlbnRpdHkvY2xhaW1zL3JvbGUiOiJBRE1JTklTVFJBVE9SIiwiZXhwIjoyNTM0MDIzMDA4MDAsImlzcyI6IkNsYXJlX0FJIiwiYXVkIjoiQ2xhcmVfQUkifQ.aUav9b92v5aeTisMyrnrhIJ-WVi1HCjNx2IXnXqQFLU";
    const url = `https://live-mt-server.wati.io/305476/api/v1/sendTemplateMessage?whatsappNumber=91${vendornumber}`;
    const headers = {
      Authorization: `Bearer ${apiKey}`,
      "Content-Type": "application/json",
    };

    try {
      const response = await axios.post(url, vendordata, { headers });
      console.log("Message sent successfully:", response.data);
    } catch (error) {
      console.error(
        "Error sending message:",
        error.response ? error.response.data : error.message
      );
    }
  };

  const sendmessagestoKae = async (vendordata, kaenumber) => {
    // alert(kaenumber);
    const apiKey =
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI3MGIwNzcyMS1lNmFiLTQ5MDQtOWMxMi1lZWQ0ZWUwMzdjMjkiLCJ1bmlxdWVfbmFtZSI6ImluZm9AYm9va3dlZGdvLmNvbSIsIm5hbWVpZCI6ImluZm9AYm9va3dlZGdvLmNvbSIsImVtYWlsIjoiaW5mb0Bib29rd2VkZ28uY29tIiwiYXV0aF90aW1lIjoiMDIvMjkvMjAyNCAwNjozNTowMCIsImRiX25hbWUiOiJtdC1wcm9kLVRlbmFudHMiLCJ0ZW5hbnRfaWQiOiIzMDU0NzYiLCJodHRwOi8vc2NoZW1hcy5taWNyb3NvZnQuY29tL3dzLzIwMDgvMDYvaWRlbnRpdHkvY2xhaW1zL3JvbGUiOiJBRE1JTklTVFJBVE9SIiwiZXhwIjoyNTM0MDIzMDA4MDAsImlzcyI6IkNsYXJlX0FJIiwiYXVkIjoiQ2xhcmVfQUkifQ.aUav9b92v5aeTisMyrnrhIJ-WVi1HCjNx2IXnXqQFLU"; // Replace with your actual WATI API key
    const url = `https://live-mt-server.wati.io/305476/api/v1/sendTemplateMessage?whatsappNumber=91${kaenumber}`;

    const headers = {
      Authorization: `Bearer ${apiKey}`,
      "Content-Type": "application/json",
    };

    try {
      const response = await axios.post(url, vendordata, { headers });
      console.log("Message sent successfully:", response.data);
      setSelectedMadapModal(false);
      success = true;
    } catch (error) {
      console.error(
        "Error sending message:",
        error.response ? error.response.data : error.message
      );
      setSelectedMadapModal(false);
      success = false;
    }
  };

  const watiTrigger = () => {
    if (
      selectedMandap &&
      selectedMandap.length
      // &&
      // mobileNumber &&
      // mobileNumber?.length
    ) {
      selectedMandap.forEach(async (eachMandap) => {
        let obj = new WatiMsg(eachMandap);
        const customername = localStorage.getItem("leadname") || "";
        const customermobileno = localStorage.getItem("leadphoneno") || "";
        const eventtype = localStorage.getItem("eventtype") || "";
        const eventdatefromlocalstorgae =
          localStorage.getItem("eventdate") || "";
        const eventdate = moment(eventdatefromlocalstorgae)?.format("DD/MM/YY");
        const customerAddresscrm = `Customer Name:${customername}, Contact Number:XXXXXXX${customermobileno?.slice(
          7,
          10
        )}, Event Type:${eventtype}, Event Date:${eventdate}`;
        const vendorAddresscrm = `Customer Name:${customername}, Event Date:${eventdate}, Event Type:${eventtype}, Vendor Name:${eachMandap?.name}, Vendor Contact Number:${eachMandap?.mobile_no}`;

        let customerAddressadmin = `Customer Name : User , Contact Number:  XXXXX XXXXX`;

        let vendorAddressadmin = `Customer Name:User , Vendor Name:${eachMandap?.name}, Vendor Contact Number:${eachMandap?.mobile_no} `;
        if (
          customerName !== null &&
          customerName !== "" &&
          mobileNumber !== null &&
          mobileNumber !== "" &&
          eventType !== null &&
          eventType !== "" &&
          eventDate !== null &&
          eventDate !== "" &&
          telecallerName !== null &&
          telecallerName !== ""
        ) {
          customerAddressadmin = `Customer Name :${customerName}, Contact Number:XXXXXXX${mobileNumber?.slice(
            7,
            10
          )}, Event Type:  ${eventType} ,Event Date:${eventDate}`;
          vendorAddressadmin = `Customer Name:${customerName},Contact Number:${mobileNumber}, Event Type:  ${eventType}, Event Date:${eventDate}, Vendor Name:${eachMandap?.name}, Vendor Contact Number:${eachMandap?.mobile_no}, Telecaller Name : ${telecallerName}`;
        } else if (
          customerName !== null &&
          customerName !== "" &&
          mobileNumber !== null &&
          mobileNumber !== ""
        ) {
          customerAddressadmin = `Customer Name :${customerName}, Contact Number:XXXXXXX${mobileNumber?.slice(
            7,
            10
          )}`;
          vendorAddressadmin = `Customer Name:${customerName}, Vendor Name:${eachMandap?.name}, Vendor Contact Number:${eachMandap?.mobile_no} `;
        } else if (mobileNumber !== null && mobileNumber !== "") {
          customerAddressadmin = `Customer Name : User, Contact Number:XXXXXXX${mobileNumber?.slice(
            7,
            10
          )}`;
        } else if (customerName !== null && customerName !== "") {
          customerAddressadmin = `Customer Name :${customerName}, Contact Number:XXXXXXX${mobileNumber?.slice(
            7,
            10
          )}`;
          vendorAddressadmin = `Customer Name:${customerName}, Vendor Name:${eachMandap?.name}, Vendor Contact Number:${eachMandap?.mobile_no} `;
        }

        const vendordataforcrm = {
          template_name: "vendor_suggestion_messages",
          broadcast_name: "Bookwedgo",
          parameters: [
            { name: "name", value: eachMandap?.kae_name },
            {
              name: "customer_address",
              value: customerAddresscrm,
            },
          ],
        };

        const kaeDataforcrm = {
          template_name: "kae_message",
          broadcast_name: "Bookwedgo",
          parameters: [
            {
              name: "customer_address",
              value: vendorAddresscrm,
            },
          ],
        };

        const vendordataforadmin = {
          template_name: "vendor_suggestion_messages",
          broadcast_name: "Bookwedgo",
          parameters: [
            { name: "name", value: eachMandap?.kae_name },
            {
              name: "customer_address",
              value: customerAddressadmin,
            },
          ],
        };

        const kaeDataforadmin = {
          template_name: "kae_message",
          broadcast_name: "Bookwedgo",
          parameters: [
            {
              name: "customer_address",
              value: vendorAddressadmin,
            },
          ],
        };

        if (mobileNumber !== null && mobileNumber !== "") {
          // await sendmessagestocustomer(obj);
          console.log("Message sending to customer currently disabled");
        }
        if (
          eventtype !== null &&
          eventdate !== null &&
          eventtype !== "" &&
          eventdate !== ""
        ) {
          await sendmessagestovendor(vendordataforcrm, eachMandap?.mobile_no);
          await sendmessagestoKae(
            kaeDataforcrm,
            eachMandap?.kae_contact_number
          );
        } else {
          await sendmessagestovendor(vendordataforadmin, eachMandap?.mobile_no);
          await sendmessagestoKae(
            kaeDataforadmin,
            eachMandap?.kae_contact_number
          );
        }
      });
      success
        ? toast.success("Check your Whatsapp!", toastAttr)
        : toast.warning("Unable to send via whatsapp!", toastAttr);
      if (success) {
        localStorage.removeItem("leadname");
        localStorage.removeItem("leadphoneno");
        localStorage.removeItem("eventtype");
        localStorage.removeItem("eventdate");
        setSelected([]);
        setSelectedMandap([]);
        setMobileNumber("");
        setcustomerName("");
        seteventDate("");
        seteventType("");
      }
    }
  };
  return (
    <Container className="vendor">
      <Row>
        <Col md={12} className="contBody">
          <Card>
            <CardBody>
              <Row className="card__title pageNav">
                <Col span={8}>
                  <Breadcrumbs data={breadcrumbData} />
                </Col>
                <Col span={5}>
                  <div style={{ float: "right" }}>
                    {selectedMandap && selectedMandap.length ? (
                      <button
                        className="btn"
                        style={{
                          // float: "right",
                          "background-color": "#f0448c",
                          color: "white",
                          width: "150px",
                        }}
                        onClick={() => {
                          setSelectedMadapModal(true);
                        }}
                      >
                        Share Mandap
                      </button>
                    ) : null}
                    <button
                      className="btn"
                      style={{
                        // float: "right",
                        "background-color": "#f0448c",
                        color: "white",
                        width: isexportingClicked ? "123px" : "100px",
                      }}
                      onClick={exportdata}
                    >
                      {isexportingClicked ? (
                        <p style={{ color: "white" }}>
                          {" "}
                          <span>Exporting </span>
                          <CircularProgress
                            size={15}
                            style={{
                              color: "white",
                              marginLeft: "5px",
                              marginTop: "3px",
                            }}
                          />
                        </p>
                      ) : (
                        "Export Data"
                      )}
                    </button>
                  </div>
                </Col>
              </Row>

              <Row className="formBtnsDiv mar-b-5 m-2">
                <Row className="mb-3" style={{ width: "100%" }}>
                  <div className="col-lg-2 col-md-2 col-sm-2  mt-2">
                    <label htmlFor="name">Mandap Name</label>
                  </div>
                  <div className="col-lg-3 col-md-3 col-sm-3">
                    <input
                      value={mandapName}
                      autoComplete="off"
                      type="text"
                      className="form-control color1"
                      name="text"
                      onChange={handleChange}
                      onKeyDown={handleKeyDown}
                    />

                    {showSuggestions && (
                      <ul
                        style={{
                          position: "absolute",
                          top: "100%",
                          left: 0,
                          right: "auto",
                          height: "250px",
                          width: "320px", // Fixed width
                          maxWidth: "320px", // Ensure it doesn't expand beyond this
                          minWidth: "320px", // Keep it consistent
                          overflow: "hidden",
                          overflowY: "auto",
                          zIndex: 999,
                          backgroundColor: "white",
                          border: "1px solid #ccc",
                          wordWrap: "break-word", // Allow content to wrap
                          overflowWrap: "break-word", // Ensures long words break properly
                        }}
                      >
                        {suggestions?.length > 0 &&
                          suggestions
                            ?.slice()
                            .sort((a, b) => {
                              const orderA =
                                a.custom_order === null
                                  ? Number.MAX_SAFE_INTEGER
                                  : a.custom_order;
                              const orderB =
                                b.custom_order === null
                                  ? Number.MAX_SAFE_INTEGER
                                  : b.custom_order;

                              return orderA - orderB; // Sort in ascending order (0 first, null last)
                            })
                            .map((suggestion, index, custom_order) => (
                              <li
                                key={suggestion?.id}
                                onClick={() => {
                                  handleSelectSuggestion(suggestion?.id);
                                  setmandapname(suggestion.mandap_name);
                                }}
                                onMouseOver={() => handleMouseOver(index)}
                                onMouseOut={() => handleMouseOut()}
                                style={{
                                  background:
                                    mandapName.toLocaleLowerCase() ==
                                    suggestion?.mandap_name.toLocaleLowerCase()
                                      ? "#eee"
                                      : "transparent",
                                  cursor: "pointer",
                                  listStyle: "none",
                                  fontFamily: "Arial",
                                  // Set font family to Arial
                                  whiteSpace: "nowrap",
                                  color:
                                    mandapName.toLocaleLowerCase() ==
                                    suggestion?.mandap_name.toLocaleLowerCase()
                                      ? "green"
                                      : "inherit",
                                  fontWeight:
                                    mandapName.toLocaleLowerCase() ==
                                    suggestion?.mandap_name.toLocaleLowerCase()
                                      ? "800"
                                      : "inherit",
                                  backgroundColor:
                                    mandapName.toLocaleLowerCase() ==
                                    suggestion?.mandap_name.toLocaleLowerCase()
                                      ? "pink"
                                      : "inherit",
                                }}
                              >
                                <p
                                  style={{
                                    fontWeight: "bold",
                                    wordBreak: "break-word", // Break long words
                                    overflowWrap: "break-word", // Ensure proper wrapping
                                    whiteSpace: "normal",
                                  }}
                                >
                                  {suggestion?.mandap_name}
                                </p>
                                <span
                                  style={{
                                    display: "block", // Ensure full-width for wrapping
                                    wordBreak: "break-word",
                                    overflowWrap: "break-word",
                                    whiteSpace: "normal",
                                  }}
                                >{`${suggestion?.area_name} in ${suggestion?.city_name} - (${suggestion?.mandap_type})`}</span>
                                <Divider component="li" />
                              </li>
                            ))}
                      </ul>
                    )}
                  </div>
                  <div className="col-lg-1.9 col-md-1.9 col-sm-1.9 ml-1   mt-2">
                    <label htmlFor="name">Mandap Status</label>
                  </div>
                  <div className="col-lg-2 col-md-2 col-sm-2">
                    <select
                      onChange={(e) => {
                        setPublishChoice(e.target.value);
                      }}
                      style={{ padding: "10px 20px", borderRadius: "5px" }}
                    >
                      <option value="">-- select status --</option>
                      <option value="publish">publish</option>
                      <option value="unpublish">un publish</option>
                    </select>
                  </div>
                  <div
                    class="form-outline"
                    data-mdb-input-init
                    className=" ml-4 col-lg-1.8"
                  >
                    <label class="form-label" className="mt-2" for="typeNumber">
                      Proximity radius
                    </label>
                  </div>
                  <div className="col-lg-2">
                    <input
                      type="number"
                      id="typeNumber"
                      class="form-control"
                      value={kms}
                      onChange={(e) => {
                        if (
                          selLocality?.length > 1 &&
                          e.target.value !== "0" &&
                          e.target.value !== ""
                        ) {
                          setselLocality([selLocality[0]]);
                        }
                        if (
                          selVenue?.length > 1 &&
                          e.target.value !== "0" &&
                          e.target.value !== ""
                        ) {
                          setselVenue([selVenue[0]]);
                        }
                        if (e.target.value >= 0 && e.target.value <= 10) {
                          setkms(e.target.value);
                        }
                      }}
                    />
                  </div>
                  <div className="col-lg-0.2 mt-3">
                    <h4>km</h4>
                  </div>
                  {/* <MdClear height={100} width={100} /> */}
                </Row>

                <Row className="mb-2" style={{ width: "100%" }}>
                  <Col xs={2} className="mt-3">
                    <label htmlFor="date">Date</label>
                  </Col>
                  <Col xs={3} className="dateTimePicker">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        renderInput={(props) => (
                          <TextField
                            {...props}
                            className="form-control pickDate"
                            style={{ width: "200px" }}
                          />
                        )}
                        onChange={handleDateChange}
                        value={date1}
                        // value={form.date_to || null}
                        // onChange={(newValue) => onChangeDateTime(newValue,'date_to')}
                      />
                    </LocalizationProvider>
                  </Col>
                  <Col xs={1} className="mt-3 ">
                    <label
                      htmlFor="date"
                      style={{ display: "flex", justifyContent: "right" }}
                    >
                      Category
                    </label>
                  </Col>
                  <Col xs={2}>
                    <select
                      onChange={(e) => {
                        setCategoryChoice(e.target.value);
                      }}
                      // style={{ padding: "10px 20px", borderRadius: "5px",marginRight:'50px' }}
                      style={{ padding: "10px 20px", borderRadius: "5px" }}
                    >
                      <option value="">-- Select Category --</option>
                      {categoryList.map((type) => {
                        return (
                          <option value={type.value} className="select-item">
                            {type.label}
                          </option>
                        );
                      })}
                    </select>
                  </Col>
                  <Col xs={1} className="mt-3">
                    <label
                      htmlFor="date"
                      style={{ display: "flex", justifyContent: "right" }}
                    >
                      Agent
                    </label>
                  </Col>
                  <Col xs={2} className="mb-1">
                    <select
                      onChange={(e) => {
                        setAgentSelected(e.target.value);
                      }}
                      style={{ padding: "10px 20px", borderRadius: "5px" }}
                    >
                      <option value="">-- Select Agent --</option>
                      {agentList.map((type) => {
                        return (
                          <option value={type.value} className="select-item">
                            {type.label}
                          </option>
                        );
                      })}
                    </select>
                  </Col>
                </Row>
                <Row
                  className="justify-content-center mb-4 ml-5"
                  style={{
                    height: "45px",
                    "background-color": "#f0448c",
                    "background-clip": "padding-box",
                    width: "1000px",
                    marginLeft: "100px",
                    borderRadius: "10px",
                  }}
                >
                  <div className="col-md-2 mt-1">
                    <select
                      onChange={handleCity}
                      value={savedCity}
                      id="homeCity"
                      type="text"
                      className="form-control"
                      style={{ color: "black", width: "100%" }}
                      name="text"
                      required=""
                    >
                      {cityList.map((obj) => {
                        return <option value={obj.value}>{obj.label}</option>;
                      })}
                    </select>
                  </div>
                  <div className="col-md-2">
                    <div className="locality">
                      <ReactMultiSelectCheckboxes
                        className="localitySearch"
                        // options={sessionStorage.getItem('localityList') ? JSON.parse(sessionStorage.getItem('localityList')) : ''}
                        options={localityList}
                        placeholderButtonLabel="Search Localities"
                        // getDropdownButtonLabel={getDropdownButtonLabel}
                        value={selLocality}
                        onChange={onChangeLocality}
                      />
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className={`venue ${category ? "disableFilter" : ""}`}>
                      <ReactMultiSelectCheckboxes
                        options={venueList}
                        placeholderButtonLabel="Search Venue"
                        value={selVenue}
                        onChange={onChangeVenue}
                      />
                    </div>
                  </div>
                  <div
                    className="col-md-2 col-sm-2 col-6 mt-1 mr-1 pd-2"
                    style={{
                      fontSize: "14px",
                      border: "0.5px solid white",
                      height: "37px",
                      width: "3px",
                      backgroundColor: "white",
                      "box-sizing": "border-box",
                      borderRadius: "5px",
                    }}
                  >
                    <div
                      className="capacity mt-2"
                      aria-describedby="simple-popover"
                      variant="contained"
                      onClick={openBudgetPopover}
                    >
                      {budgetLabel} &nbsp;
                      <span>
                        <FaChevronDown size="10px" />
                      </span>
                    </div>
                    <Popover
                      open={Boolean(budgetAnchor)}
                      anchorEl={budgetAnchor}
                      id="simple-popover"
                      onClose={() => handleRangeClose("budget")}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                    >
                      <Typography variant="">
                        <div
                          style={{ padding: "50px 30px" }}
                          className="rangePopover"
                        >
                          <Box sx={{ width: 200 }}>
                            <Slider
                              value={budget}
                              onChange={(e, newVal) => {
                                handleRangeChange(e, newVal, setbudget);
                                setbudgetselected(true);
                              }}
                              valueLabelDisplay="on"
                              min={5000}
                              max={500000}
                            />
                          </Box>
                          <div>
                            <input
                              type="number"
                              className="rangeInput"
                              value={budget[0]}
                              onChange={(e) => {
                                setbudget([e.target.value, budget[1]]);
                                setbudgetselected(true);
                              }}
                            />
                            &nbsp;&nbsp; to &nbsp;&nbsp;{" "}
                            <input
                              type="number"
                              className="rangeInput"
                              value={budget[1]}
                              onChange={(e) => {
                                setbudget([budget[0], e.target.value]);
                                setbudgetselected(true);
                              }}
                            />
                          </div>
                        </div>
                      </Typography>
                    </Popover>
                  </div>
                  <div
                    className="col-lg-2 col-md-2 col-sm-2 col-6 mt-1 pd-2"
                    style={{
                      fontSize: "14px",
                      border: "0.5px solid white",
                      height: "37px",
                      width: "4px",
                      backgroundColor: "white",
                      "box-sizing": "border-box",
                      borderRadius: "5px",
                    }}
                  >
                    <div
                      className="capacity mt-2"
                      aria-describedby="simple-popover"
                      variant="contained"
                      onClick={openCapacityPopover}
                    >
                      {capacityLabel} &nbsp;
                      <span>
                        <FaChevronDown size="10px" />
                      </span>
                    </div>
                    <Popover
                      open={Boolean(anchor)}
                      anchorEl={anchor}
                      id="simple-popover"
                      onClose={() => handleRangeClose("capacity")}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                    >
                      <Typography variant="">
                        <div
                          style={{ padding: "50px 30px" }}
                          className="rangePopover"
                        >
                          <Box sx={{ width: 300 }}>
                            <Slider
                              value={capacity}
                              onChange={(e, newVal) => {
                                handleRangeChange(e, newVal, setcapacity);
                                setcapacityselected(true);
                              }}
                              valueLabelDisplay="on"
                              min={0}
                              max={3000}
                            />
                          </Box>
                          <div>
                            <input
                              type="number"
                              className="rangeInput"
                              value={capacity[0]}
                              onChange={(e) => {
                                setcapacity([e.target.value, capacity[1]]);
                                setcapacityselected(true);
                              }}
                            />
                            &nbsp;&nbsp; to &nbsp;&nbsp;{" "}
                            <input
                              type="number"
                              className="rangeInput"
                              value={capacity[1]}
                              onChange={(e) => {
                                setcapacity([capacity[0], e.target.value]);
                                setcapacityselected(true);
                              }}
                            />
                          </div>
                        </div>
                      </Typography>
                    </Popover>
                  </div>
                </Row>
              </Row>

              <Row className="formBtnsDiv mar-b-10 m-2">
                <div
                  className="mb-3 d-flex justify-content-between align-items-center"
                  style={{ width: "100%" }}
                >
                  <div className="d-flex justify-content-start">
                    <button
                      className="btn"
                      noTransition={true}
                      style={{ backgroundColor: "#f0448c", color: "white" }}
                      onClick={() => handleSubmit()}
                      disabled={query === "" && savedCity === null}
                    >
                      Check Availability
                    </button>
                    {/* <button
                      className="btn"
                      noTransition={true}
                      style={{ backgroundColor: "#f0448c", color: "white" }}
                      onClick={() => handleresetfilter()}
                      // disabled={query === "" && savedCity === null}
                    >
                      Clear Filters
                    </button> */}
                    {filteredData &&
                    filteredData?.length &&
                    searchedKM?.kms !== "" &&
                    searchedKM?.kms !== "0" ? (
                      <div>
                        <p>
                          <strong>Note:</strong>
                          {searchedKM?.kms !== ""
                            ? " Clear the proximity radius to search by multiple localities. "
                            : null}
                        </p>
                        {searchedKM?.kms !== "" &&
                          searchedKM?.locality !== "" && (
                            <p>
                              Showing results for{" "}
                              <strong>{searchedKM?.locality}</strong> within{" "}
                              <strong>{searchedKM?.kms} km</strong>{" "}
                              Approximately.
                            </p>
                          )}
                      </div>
                    ) : null}
                  </div>
                  {tableData && tableData?.length > 10 ? (
                    <div className="d-flex  align-items-center">
                      <div className=" mr-3 mt-2">
                        <label htmlFor="name">Filter</label>
                      </div>
                      <input
                        value={searchTerm}
                        type="text"
                        className="form-control color1"
                        placeholder="Search Mandamap"
                        name="text"
                        required=""
                        onChange={handleInputChange}
                        style={{ maxWidth: "250px" }}
                      />
                    </div>
                  ) : null}
                </div>
              </Row>

              <Row className="vendorList">
                <BootstrapTable
                  bootstrap4
                  wrapperClasses="table-responsive  pad-70 pl-2"
                  data={filteredData}
                  columns={cols}
                  keyField="id"
                  containerStyle={{
                    width: filteredData?.length > 0 ? "100%" : "40%",
                  }}
                  hover
                  noDataIndication="No data found"
                  pagination={options}
                  selectRow={selectRow}
                />
                {isPageLoading && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      width: "100%",
                    }}
                  >
                    <CircularProgress style={{ color: "rgb(240, 68, 140)" }} />
                  </Box>
                )}
              </Row>
              <Modal
                className="custom-modal"
                size="xl"
                show={showModal}
                onHide={() => setShowModal(false)}
              >
                <Modal.Header closeButton>
                  <Modal.Title>
                    {selectedRow && selectedRow.name} Details
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  {modalData ? (
                    <BootstrapTable
                      keyField="id"
                      data={modalData}
                      columns={modalColumns}
                    />
                  ) : (
                    <p>Loading modal data...</p>
                  )}
                </Modal.Body>
              </Modal>

              <Modal
                show={
                  seletedMadapModal && selectedMandap && selectedMandap.length
                }
                onHide={() => setSelectedMadapModal(false)}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Venue Details</Modal.Title>
                  <div>
                    <label style={{ fontWeight: "bold", fontSize: "11px" }}>
                      Switch to Enter Customer Details (optional)
                    </label>
                    <PinkSwitch
                      checked={customerchecked}
                      onChange={handlecustomertoggle}
                      inputProps={{ "aria-label": "controlled" }}
                      defaultChecked
                    />
                  </div>
                </Modal.Header>

                <Modal.Body>
                  {selectedMandap &&
                    selectedMandap.length &&
                    selectedMandap.map((eachMandap, i) => (
                      <div
                        key={i}
                        className="d-flex justify-content-between align-items-center mt-3"
                        style={{
                          borderBottom: "1px solid #808080",
                          padding: "2px",
                          margin: "3px",
                        }}
                      >
                        <div style={{ margin: "3px" }}>{eachMandap?.name}</div>
                        <MdDelete
                          style={{ fontSize: "20px", cursor: "pointer" }}
                          onClick={() => {
                            handleRemoveSelectedMandap(i);
                          }}
                        />
                      </div>
                    ))}

                  {customerchecked && (
                    <>
                      <input
                        value={mobileNumber}
                        type="text"
                        className="form-control color1 mt-5 mb-1 mx-10"
                        placeholder="Enter Mobile Number"
                        name="text"
                        required=""
                        onChange={handleMobileNumberChange}
                        // style={{maxWidth:"250px"}}
                      />

                      <input
                        value={customerName}
                        type="text"
                        className="form-control color1 mt-0 mb-1 mx-10"
                        placeholder="Enter Customer Name"
                        name="leadname"
                        required
                        onChange={handlecustomerName}
                      />

                      <input
                        value={telecallerName}
                        type="text"
                        className="form-control color1 mt-0 mb-1 mx-10"
                        placeholder="Enter Telecaller Name "
                        name="telecaller_name"
                        required
                        onChange={handletelecallername}
                      />

                      <input
                        value={eventtype}
                        type="text"
                        className="form-control color1 mt-0 mb-1 mx-10"
                        placeholder="Enter Event Type"
                        name="eventtype"
                        required
                        onChange={handleEventType}
                      />

                      <input
                        value={eventdate}
                        type="text"
                        className="form-control color1 mt-0 mb-3 mx-10"
                        placeholder="Enter Event Date "
                        name="event_date"
                        required
                        onChange={handleChangeEventDate}
                      />
                    </>
                  )}
                </Modal.Body>

                <Modal.Footer>
                  <div
                    className="d-flex justify-content-center items-center my-4"
                    style={{ width: "100%" }}
                  >
                    <button
                      className="btn"
                      noTransition={true}
                      style={{
                        backgroundColor: "#f0448c",
                        color: "white",
                        padding: "3px",
                      }}
                      onClick={() => watiTrigger()}
                      disabled={!selectedMandap.length && mobileNumber?.length}
                    >
                      Share Mandap
                    </button>
                  </div>
                </Modal.Footer>
              </Modal>

              <MyVerticallyCenteredModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                data={mandapDetailmodalData}
              />
              <ToastContainer
                position="top-center"
                autoClose={3000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
      <style>
        {`
        .react-bootstrap-table tbody tr .react-bs-table-no-data{
          text-align: start;
        }
        `}
      </style>
    </Container>
  );
};

export default Report;
